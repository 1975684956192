
import { Vue, Component } from 'vue-property-decorator';
import { AxiosError } from 'axios';
import { ErrorResponseModel } from '@/model/StaffManager';
import PasswordMeter from '@/components/Parts/PasswordMeter.vue';
import PasswordChangeInfo from '@/model/user/PasswordChangeInfo';
import Password from '@/model/user/Password';
import UserApi from '@/module/api/UserApi';

@Component({
  components: {
    PasswordMeter
  }
})
export default class PasswordChange extends Vue {
  passwordInfo: PasswordChangeInfo = new PasswordChangeInfo();
  confirmPassword: Password = new Password();
  changedFlg: boolean = false;

  /**
   * パスワード変更処理
   */
  change(): void {
    UserApi.passwordChange(this.passwordInfo).then(() => {
      this.changedFlg = true;
    }).catch((error: AxiosError<ErrorResponseModel>) => {
      if (error.response?.data.code === 'NoExistsRecord') {
        this.$root.$emit('event-show-snackbar-infinity', '現在のパスワードが正しくありません。');
      }
    });
  }

  /**
   * 変更ボタン活性判定
   */
  isValid(): boolean {
    // パスワード
    if (this.passwordInfo.newPassword.value.length < 6 || this.passwordInfo.newPassword.value.length > 36) return true;
    const reg = new RegExp(/^[a-zA-Z0-9!-/:-@¥[-`{-~]*$/);
    const res = reg.test(this.passwordInfo.newPassword.value);
    if (!res) return true;
    if (this.passwordInfo.newPassword.value !== this.confirmPassword.value) return true;

    return false;
  }
}
